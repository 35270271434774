import PropTypes from "prop-types";
import React from "react";
import { changeLanguage } from "redux-multilanguage";

const LanguageCurrencyChanger = ({
  currency,
  setCurrency,
  currentLanguageCode,
  dispatch,
}) => {
  const changeLanguageTrigger = (e) => {
    const languageCode = e.target.value;
    dispatch(changeLanguage(languageCode));
  };

  const setCurrencyTrigger = (e) => {
    const currencyName = e.target.value;
    setCurrency(currencyName);
  };

  return (
    <div className="language-currency-wrap">
      <div className="same-language-currency language-style">
        <span>
          {currentLanguageCode === "tr"
            ? "Turkish"
            : currentLanguageCode === "en"
            ? "English"
            : currentLanguageCode === "fn"
            ? "French"
            : currentLanguageCode === "de"
            ? "Germany"
            : ""}{" "}
          <i className="fa fa-angle-down" />
        </span>
        <div className="lang-car-dropdown">
          <ul>
            <li>
              <button value="tr" onClick={(e) => changeLanguageTrigger(e)}>
                Turkish
              </button>
            </li>
            <li>
              <button value="en" onClick={(e) => changeLanguageTrigger(e)}>
                English
              </button>
            </li>
            <li>
              <button value="fn" onClick={(e) => changeLanguageTrigger(e)}>
                French
              </button>
            </li>
            <li>
              <button value="de" onClick={(e) => changeLanguageTrigger(e)}>
                German
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="same-language-currency use-style">
        <span>
          {currency.currencyName} <i className="fa fa-angle-down" />
        </span>
        <div className="lang-car-dropdown">
          <ul>
            <li>
              <button value="TRY" onClick={(e) => setCurrencyTrigger(e)}>
                TRY
              </button>
            </li>
            <li>
              <button value="USD" onClick={(e) => setCurrencyTrigger(e)}>
                USD
              </button>
            </li>
            <li>
              <button value="EUR" onClick={(e) => setCurrencyTrigger(e)}>
                EUR
              </button>
            </li>
            <li>
              <button value="GBP" onClick={(e) => setCurrencyTrigger(e)}>
                GBP
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="same-language-currency">
        <p>
          <a
            target="_blank"
            href={
              currentLanguageCode === "tr"
                ? "https://api.whatsapp.com/send?phone=905375418777"
                : currentLanguageCode === "en"
                ? "https://api.whatsapp.com/send?phone=1234567890"
                : currentLanguageCode === "fn"
                ? "https://api.whatsapp.com/send?phone=9876543210"
                : currentLanguageCode === "de"
                ? "https://api.whatsapp.com/send?phone=9876543210"
                : "https://api.whatsapp.com/send?phone=905375418777"
            }
          >
            {currentLanguageCode === "tr"
              ? "WhatsApp'tan bize ulaşın"
              : currentLanguageCode === "en"
              ? "Contact us on WhatsApp"
              : currentLanguageCode === "fn"
              ? "Contactez-nous sur WhatsApp"
              : currentLanguageCode === "de"
              ? "Kontaktieren Sie uns über WhatsApp"
              : "WhatsApp'tan bize ulaşın"}
          </a>
        </p>
      </div>
    </div>
  );
};

LanguageCurrencyChanger.propTypes = {
  setCurrency: PropTypes.func,
  currency: PropTypes.object,
  currentLanguageCode: PropTypes.string,
  dispatch: PropTypes.func,
};

export default LanguageCurrencyChanger;
