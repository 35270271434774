import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { getDiscountPrice } from "../../helpers/product";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import axios from "axios";
import token from "../../data/token.json";

const OrderSearch = ({ location, currency }) => {
  let cartTotalPrice = 0;
  let shippingPrice = 80;
  const { pathname } = location;
  const locationOrder = useLocation();
  const [orderDetail, setOrderDetail] = useState([]);
  const [orderNo, setOrderNo] = useState("");
  const searchParams = new URLSearchParams(locationOrder.search);
  const orderNoParam = searchParams.get("orderNo");

  useEffect(() => {
    if (orderNoParam) {
      setOrderNo(orderNoParam);
      getOrder();
    }
  }, [locationOrder.search]);

  const getOrder = async () => {
    const apiUrl = `https://api.minyatursepeti.com/api/Order/GetOrderDetail?orderNo=${
      orderNoParam ? orderNoParam : orderNo
    }`;
    try {
      await axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => response.data)
        .then((response) => {
          setOrderDetail(response);
        });
    } catch (error) {
      console.error("Veri çekme hatası:", error);
    }
  };

  return (
    <Fragment>
      <MetaTags>
        <title>Minyatür Sepeti | Sipariş</title>
        <meta
          name="description"
          content="Order Detail Page of Minyatür Sepeti"
        />
      </MetaTags>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>
        Anasayfa
      </BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
        SİPARİŞ SORGULA
      </BreadcrumbsItem>
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb />
        <div className="checkout-area pt-95 pb-100">
          <div className="container">
            <div className="col-lg-6" style={{ margin: "0 auto" }}>
              {" "}
              <div className="row">
                <div className="col-lg-4" style={{ margin: "auto 0" }}>
                  <h4>Sipariş Numarası</h4>
                </div>
                <div className="col-lg-8" style={{ padding: "0" }}>
                  {" "}
                  <input
                    type="text"
                    onChange={(e) => setOrderNo(e.target.value)}
                  ></input>
                </div>
              </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  width: "25%",
                  float: "right",
                  marginTop: "2%",
                }}
              >
                {" "}
                <Link
                  to={`${process.env.PUBLIC_URL}/my-order?orderNo=${orderNo}`}
                >
                  <input
                    value="Sorgula"
                    style={{ cursor: "pointer", textAlign: "center" }}
                  ></input>
                </Link>
              </div>
            </div>
            <br></br>
            <div
              className="row"
              style={{
                marginTop: "5%",
              }}
            >
              <div className="col-lg-6" style={{ margin: "0 auto" }}>
                {orderDetail.map((order) => (
                  <div className="row">
                    <div className="col-lg-12" style={{ margin: "0 auto" }}>
                      <div className="your-order-area">
                        <h3 style={{ textAlign: "center" }}>Sipariş Detayı</h3>
                        <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                          {order.orderStatus}
                        </h4>
                        <h4 style={{ textAlign: "center" }}>
                          Sipariş No:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {order.orderNo}
                          </span>
                        </h4>
                        <h3 style={{ textAlign: "center" }}>
                          {order.customer.firstName} {order.customer.lastName}
                        </h3>
                        <br />
                        <h4 style={{ textAlign: "center" }}>
                          <span>{order.orderInfo}</span>
                        </h4>
                        <br />
                        <br />
                        {order.customer.customerAddresses.map((cust) => {
                          return (
                            <div
                              className="your-order-wrap gray-bg-4"
                              style={{ paddingBottom: "5px" }}
                            >
                              <div className="your-order-product-info">
                                <div className="your-order-top">
                                  <ul>
                                    <li>Fatura Adresi</li>
                                  </ul>
                                </div>
                                <div className="your-order-middle">
                                  <ul>
                                    <li>
                                      <span className="order-middle-left">
                                        Adres
                                      </span>
                                      <span className="order-price">
                                        {cust.addressLine1}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="order-middle-left"></span>
                                      <span className="order-price">
                                        {cust.addressLine2}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="order-middle-left">
                                        Şehir
                                      </span>
                                      <span className="order-price">
                                        {cust.city}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="order-middle-left">
                                        Ülke
                                      </span>
                                      <span className="order-price">
                                        {cust.country}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="order-middle-left">
                                        Telefon
                                      </span>
                                      <span className="order-price">
                                        {cust.phone}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="payment-method"></div>
                            </div>
                          );
                        })}
                        {order.customer.customerAddresses.map((cust) => {
                          return (
                            <div
                              className="your-order-wrap gray-bg-4"
                              style={{ paddingTop: "5px" }}
                            >
                              <div className="your-order-product-info">
                                <div className="your-order-top">
                                  <ul>
                                    <li>Teslimat Adresi</li>
                                  </ul>
                                </div>
                                <div className="your-order-middle">
                                  <ul>
                                    <li>
                                      <span className="order-middle-left">
                                        Adres
                                      </span>
                                      <span className="order-price">
                                        {cust.addressLine1}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="order-middle-left"></span>
                                      <span className="order-price">
                                        {cust.addressLine2}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="order-middle-left">
                                        Şehir
                                      </span>
                                      <span className="order-price">
                                        {cust.city}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="order-middle-left">
                                        Ülke
                                      </span>
                                      <span className="order-price">
                                        {cust.country}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="order-middle-left">
                                        Telefon
                                      </span>
                                      <span className="order-price">
                                        {cust.phone}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="payment-method"></div>
                            </div>
                          );
                        })}

                        <div className="your-order-wrap gray-bg-4">
                          <div className="your-order-product-info">
                            <div className="your-order-top">
                              <ul>
                                <li>Ürün(ler)</li>
                                <li>Fiyat</li>
                              </ul>
                            </div>
                            <div className="your-order-middle">
                              <ul>
                                {order.orderItems.map((orderItem, key) => {
                                  const discountedPrice = getDiscountPrice(
                                    orderItem.product.unitPrice,
                                    orderItem.product.discount
                                  );
                                  const finalProductPrice = (
                                    orderItem.product.unitPrice *
                                    currency.currencyRate
                                  ).toFixed(2);
                                  const finalDiscountedPrice = (
                                    discountedPrice * currency.currencyRate
                                  ).toFixed(2);

                                  discountedPrice != null
                                    ? (cartTotalPrice +=
                                        finalDiscountedPrice *
                                        orderItem.quantity)
                                    : (cartTotalPrice +=
                                        finalProductPrice * orderItem.quantity);
                                  return (
                                    <li key={key}>
                                      <span className="order-middle-left">
                                        <Link
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/product/" +
                                            orderItem.product.id
                                          }
                                        >
                                          {orderItem.product.name}{" "}
                                        </Link>
                                        X {orderItem.quantity}
                                      </span>{" "}
                                      <span className="order-price">
                                        {discountedPrice !== null
                                          ? currency.currencySymbol +
                                            (
                                              finalDiscountedPrice *
                                              orderItem.quantity
                                            ).toFixed(2)
                                          : currency.currencySymbol +
                                            (
                                              finalProductPrice *
                                              orderItem.quantity
                                            ).toFixed(2)}
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                            <div className="your-order-bottom">
                              {cartTotalPrice > 1000 ? (
                                <ul>
                                  <li className="your-order-shipping">Kargo</li>
                                  <li>Ücretsiz</li>
                                </ul>
                              ) : (
                                <ul>
                                  <li className="your-order-shipping">Kargo</li>
                                  <li>₺80,00</li>
                                </ul>
                              )}
                            </div>
                            <div className="your-order-total">
                              <ul>
                                <li className="order-total">Toplam</li>
                                <li>
                                  {cartTotalPrice >= 1000 ? (
                                    <li>
                                      {currency.currencySymbol +
                                        cartTotalPrice.toFixed(2)}
                                    </li>
                                  ) : (
                                    <li>
                                      {currency.currencySymbol +
                                        (cartTotalPrice +=
                                          shippingPrice).toFixed(2)}
                                    </li>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="payment-method"></div>
                        </div>
                        <div className="place-order mt-25"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-6" style={{ margin: "0 auto" }}>
                <div className="your-order-area">
                  <h3 style={{ textAlign: "center" }}>Siparişiniz Alındı </h3>
                  <h4 style={{ textAlign: "center" }}>
                    Sipariş No:{" "}
                    <span style={{ fontWeight: "bold" }}>{orderNo}</span>
                  </h4>
                  <h4 style={{ textAlign: "center" }}>
                    Sipariş detayları e-posta adresinize gönderildi.
                  </h4>
                  <div className="your-order-wrap gray-bg-4">
                    <div className="your-order-product-info">
                      <div className="your-order-top">
                        <ul>
                          <li>Ürün</li>
                          <li>Fiyat</li>
                        </ul>
                      </div>
                      <div className="your-order-middle">
                        <ul>
                          {cartItems.map((cartItem, key) => {
                            const discountedPrice = getDiscountPrice(
                              cartItem.unitPrice,
                              cartItem.discount
                            );
                            const finalProductPrice = (
                              cartItem.unitPrice * currency.currencyRate
                            ).toFixed(2);
                            const finalDiscountedPrice = (
                              discountedPrice * currency.currencyRate
                            ).toFixed(2);

                            discountedPrice != null
                              ? (cartTotalPrice +=
                                  finalDiscountedPrice * cartItem.quantity)
                              : (cartTotalPrice +=
                                  finalProductPrice * cartItem.quantity);
                            return (
                              <li key={key}>
                                <span className="order-middle-left">
                                  {cartItem.name} X {cartItem.quantity}
                                </span>{" "}
                                <span className="order-price">
                                  {discountedPrice !== null
                                    ? currency.currencySymbol +
                                      (
                                        finalDiscountedPrice * cartItem.quantity
                                      ).toFixed(2)
                                    : currency.currencySymbol +
                                      (
                                        finalProductPrice * cartItem.quantity
                                      ).toFixed(2)}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="your-order-bottom">
                        <ul>
                          <li className="your-order-shipping">Shipping</li>
                          <li>Free shipping</li>
                        </ul>
                      </div>
                      <div className="your-order-total">
                        <ul>
                          <li className="order-total">Toplam</li>
                          <li>
                            {currency.currencySymbol +
                              cartTotalPrice.toFixed(2)}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="payment-method"></div>
                  </div>
                  <div className="place-order mt-25"></div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

OrderSearch.propTypes = {
  cartItems: PropTypes.array,
  currency: PropTypes.object,
  location: PropTypes.object,
  orderNo: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
    currency: state.currencyData,
    orderNo: state.responseData,
  };
};

export default connect(mapStateToProps)(OrderSearch);
