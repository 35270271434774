import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const ShopSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();

  const handleSearch = () => {
    if (searchTerm.trim() !== "") {
      history.push(`/Search?search=${searchTerm}`);
    } else {
      console.log("Lütfen geçerli bir arama terimi girin.");
    }
  };

  return (
    <div className="sidebar-widget">
      <h4 className="pro-sidebar-title">Ürün Arama </h4>
      <div className="pro-sidebar-search mb-50 mt-25">
        <form
          className="pro-sidebar-search-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Minyatür Sepeti'nde arayın"
          />
          <button type="submit">
            <i className="pe-7s-search" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ShopSearch;
