import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import { fetchProducts } from "./redux/actions/productActions";
import rootReducer from "./redux/reducers/rootReducer";
import token from "./data/token.json";
import axios from "axios";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";

import { composeWithDevTools } from "redux-devtools-extension";

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);

//https://api.minyatursepeti.com/api/Product

// const getProducts = async () => {
//   return await axios
//     .get("https://api.minyatursepeti.com/api/Product", {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .then((response) => store.dispatch(fetchProducts(response.data)))
//     .catch((err) => {
//       console.log(err);
//     });
// };

const getProducts = async () => {
  try {
    const response = await axios.get(
      "https://api.minyatursepeti.com/api/Product/GetProducts",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    store.dispatch(fetchProducts(response.data));
  } catch (err) {
    console.log(err);
  }
};

getProducts();
// fetch products from json file
//store.dispatch(fetchProducts(products));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// getProducts();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
