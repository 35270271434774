import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const MobileSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();

  const handleSearch = () => {
    if (searchTerm.trim() !== "") {
      history.push(`/Search?search=${searchTerm}`);
    } else {
      console.log("Lütfen geçerli bir arama terimi girin.");
    }
  };
  return (
    <div className="offcanvas-mobile-search-area">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
      >
        <input
          type="search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Minyatür Sepeti'nde Ara"
        />
        <button type="submit">
          <i className="fa fa-search" />
        </button>
      </form>
    </div>
  );
};

export default MobileSearch;
