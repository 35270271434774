import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";

const MobileNavMenu = ({ strings }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("ID"));
  const history = useHistory();

  const handleLogout = async () => {
    localStorage.removeItem("ID");
    setIsLoggedIn(false);
    history.push("/Anasayfa");
  };

  const menu = isLoggedIn ? (
    <li className="menu-item">
      <Link to={process.env.PUBLIC_URL + "/Hesabım"}>
        {strings["MyAccount"]}
      </Link>{" "}
    </li>
  ) : (
    <li className="menu-item">
      <Link to={process.env.PUBLIC_URL + "/Uyelik"}>{strings["login"]}</Link>
    </li>
  );
  const logout = isLoggedIn ? (
    <li className="menu-item">
      <Link to="" onClick={handleLogout}>
        {strings["logout"]}
      </Link>{" "}
    </li>
  ) : (
    <li className="menu-item"></li>
  );

  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li className="menu-item">
          <Link to={process.env.PUBLIC_URL + "/Anasayfa"}>
            {strings["home"]}
          </Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/Hakkımızda"}>
            {strings["about_us"]}
          </Link>
        </li>
        <li className="menu-item">
          <Link to={process.env.PUBLIC_URL + "/Koleksiyon"}>
            {strings["collection"]}
          </Link>
        </li>
        <li className="menu-item">
          <Link to={process.env.PUBLIC_URL + "/SSS"}>{strings["faq"]}</Link>
        </li>
        <li className="menu-item">
          <Link to={process.env.PUBLIC_URL + "/İletişim"}>
            {strings["contact_us"]}
          </Link>
        </li>
        {menu}
        {logout}
      </ul>
    </nav>
  );
};

MobileNavMenu.propTypes = {
  strings: PropTypes.object,
};

export default multilanguage(MobileNavMenu);
